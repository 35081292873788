import ProductCustomizableOption from 'Component/ProductCustomizableOption';
import {
    ProductCustomizableOptionsComponent as SourceProductCustomizableOptions,
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.component';
import { ChameleonType } from 'Type/ProductList.type';

/** @namespace Theme/Component/ProductCustomizableOptions/Component */
export class ProductCustomizableOptionsComponent extends SourceProductCustomizableOptions {
    static propTypes = {
        ...SourceProductCustomizableOptions.propTypes,
        chameleon: ChameleonType,
    };

    renderOptionGroup(group) {
        const { chameleon } = this.props;

        const {
            title,
            value,
            type,
            required,
            uid,
        } = group;

        const { updateSelectedValues } = this.props;

        return (
            <ProductCustomizableOption
              key={ uid }
              uid={ uid }
              title={ title }
              options={ value }
              isRequired={ required }
              type={ type }
              updateSelectedValues={ updateSelectedValues }
              chameleon={ chameleon }
            />
        );
    }
}

export default ProductCustomizableOptionsComponent;
