/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ProductBundleOptionComponent as SourceProductBundleOption,
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.component';
import { ___ } from 'Util/Format';
import { bundleOptionToLabel } from 'Util/Product/Transform';

/** @namespace Theme/Component/ProductBundleOption/Component */
export class ProductBundleOptionComponent extends SourceProductBundleOption {
    getLabel(option) {
        const { currencyCode } = this.props;
        const {
            baseLabel,
            priceLabel,
        } = bundleOptionToLabel(option, currencyCode);

        return (
            <div block="ProductBundleItem" elem="Label">
                { ___(baseLabel) }
                { priceLabel && (
                    <strong block="ProductBundleItem" elem="PriceLabel">{ ` ${priceLabel}` }</strong>
                ) }
            </div>
        );
    }

    renderOptionGroupTitle(title) {
        const { isRequired } = this.props;

        return (
            <div block="ProductBundleItem" elem="Heading">
                { `${title}:` }
                { isRequired && <strong block="ProductBundleItem" elem="Required"> *</strong> }
            </div>
        );
    }
}

export default ProductBundleOptionComponent;
