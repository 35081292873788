/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import Field from 'Component/Field';
import {
    ProductCustomizableOptionComponent as SourceProductCustomizableOption,
} from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.component';
import { ConfigFieldType } from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.config';
import { ChameleonType } from 'Type/ProductList.type';
import { getSubLabelFromMaxCharacters } from 'Util/Product/Extract';
import { customizableOptionToLabel } from 'Util/Product/Transform';

import './ProductCustomizableOption.style';

/** @namespace Theme/Component/ProductCustomizableOption/Component */
export class ProductCustomizableOptionComponent extends SourceProductCustomizableOption {
    static propTypes = {
        ...SourceProductCustomizableOption.propTypes,
        chameleon: ChameleonType,
    };

    getLabel(option, overrideBase = null, overridePrice = null) {
        const {
            chameleon,
            currencyCode,
            isRequired,
            type,
        } = this.props;

        const {
            baseLabel,
            priceLabel,
        } = customizableOptionToLabel(option, currencyCode);

        const hideColon = [
            ConfigFieldType.SELECT,
            ConfigFieldType.RADIO,
            ConfigFieldType.CHECKBOX,
            ConfigFieldType.MULTI,
        ].includes(type);

        const appendString = hideColon ? '' : ':';
        const actualLabel = (overridePrice || priceLabel).trim();

        return (
            <div block="ProductCustomizableItem" elem="Label">
                { overrideBase || baseLabel }
                { chameleon?.is_option_price_visible && actualLabel && (
                    <strong block="ProductCustomizableItem" elem="PriceLabel">
                        { ` ${actualLabel}` }
                    </strong>
                ) }
                { appendString }
                { (isRequired && (
                    <span
                      block="Field"
                      elem="Label"
                      mods={ { isRequired } }
                    />
                )) }

            </div>
        );
    }

    renderOptionGroupTitle(label) {
        const { isRequired, type } = this.props;

        const showRequired = [
            ConfigFieldType.SELECT,
            ConfigFieldType.RADIO,
            ConfigFieldType.CHECKBOX,
            ConfigFieldType.MULTI,
        ].includes(type);

        return (
            <div block="ProductCustomizableItem" elem="HeadingBold">
                { label }
                { showRequired && (
                    <>
                        :
                        <span
                          block="Field"
                          elem="Label"
                          mods={ { isRequired } }
                        />
                    </>
                ) }
            </div>
        );
    }

    renderDefaultValue(option) {
        const {
            title, fieldType, isRequired, uid,
        } = this.props;
        const { value } = this.state;
        const { max_characters, sku } = option;
        const label = this.getLabel(option, title);
        const subLabel = getSubLabelFromMaxCharacters(max_characters, value);
        const urlValue = this.getOptionValueByUrl(sku);

        return (
            <>
                { this.renderOptionGroupTitle(label) }
                <Field
                  type={ fieldType }
                  validationRule={ {
                      isRequired,
                      range: { max: max_characters > 0 ? max_characters : null },
                  } }
                  attr={ {
                      id: uid,
                      name: uid,
                      placeholder: '',
                      value: urlValue ?? value ?? null,
                  } }
                  subLabel={ subLabel }
                  events={ {
                      onChange: this.updateValues.bind(this),
                  } }
                  validateOn={ ['onBlur', 'onChange'] }
                />
            </>
        );
    }

    /**
     * Get an option value from the URL
     *
     * Using the product option sku this checks the URL for a matching query parameter and returns it value
     *
     * @param optionSku
     * @returns {string|null}
     */
    getOptionValueByUrl(optionSku) {
        const queryString = window.location.search;

        if (typeof queryString !== 'string') {
            return null;
        }

        const params = new URLSearchParams(queryString);

        return params.get(optionSku);
    }
}

export default ProductCustomizableOptionComponent;
