import {
    ProductPriceComponent as SourceProductPrice,
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

export const DisplayProductPricesInCatalog = {
    INCL_TAX: 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX',
    EXCL_TAX: 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX',
    BOTH: 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH',
};

/** @namespace Theme/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    renderTierPrice() {
        return null;
    }

    renderPriceWithOrWithoutTax(
        basePrice,
        taxPrice,
        label,
    ) {
        const {
            displayTaxInPrice,
        } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <>
                { this.renderPrice(basePrice, label) }
                { this.renderSubPrice(taxPrice) }
            </>
        );
    }
}

export default ProductPriceComponent;
