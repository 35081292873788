import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

/** @namespace Theme/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
