import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static defaultProps = {
        ...super.defaultProps,
        imageLoad: 'lazy',
    };

    containerProps() {
        const {
            imageLoad,
        } = this.props;

        return {
            ...super.containerProps(),
            imageLoad,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
