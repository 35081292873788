/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getProductClickData, getProductImpressionsData } from '../data/list';
import { GTM_EVENT_KEY_IMPRESSIONS, GTM_EVENT_KEY_PRODUCT_CLICK } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback, waitForCallback } from '../util/wait';

export const IMPRESSION_PUSH_TIMER = 2000;
export const MAX_IMPRESSION_BATCH = 24;
export const MIN_IMPRESSION_BATCH = 1;
export const DEFAULT_IMPRESSION_BATCH = 6;

/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
const products = [];
const positions = [];
// eslint-disable-next-line fp/no-let
let pushTimer;
/* eslint-enable @scandipwa/scandipwa-guidelines/export-level-one */

/** @namespace Scandiweb/Gtm/Event/List/fireImpressionEvent */
export const fireImpressionEvent = async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    await waitForCallback(
        () => getStore().getState().UrlRewritesReducer?.urlRewrite
            ?.type
    );
    const { gtm_impressions_batch_limit } = getStore().getState().ConfigReducer.gtm?.events;
    const impressionBatchLimit = (gtm_impressions_batch_limit >= MIN_IMPRESSION_BATCH
    && gtm_impressions_batch_limit <= MAX_IMPRESSION_BATCH)
        ? gtm_impressions_batch_limit : DEFAULT_IMPRESSION_BATCH;

    products.push(product);
    positions.push(position);

    clearTimeout(pushTimer);

    pushTimer = setTimeout(async () => {
        if (products.length === 0) {
            return;
        }

        const productData = await getProductImpressionsData(
            products,
            positions,
            forcedList,
            urlPathname,
            getStore().getState().UrlRewritesReducer,
            category
        );

        pushToDataLayer({
            event: GTM_EVENT_KEY_IMPRESSIONS,
            ...productData,
        });

        products.splice(0);
        positions.splice(0);
    }, IMPRESSION_PUSH_TIMER);

    if (products.length >= impressionBatchLimit) {
        clearTimeout(pushTimer);

        const productsToPush = products.splice(0, impressionBatchLimit);
        const positionsToPush = positions.splice(0, impressionBatchLimit);
        const productData = await getProductImpressionsData(
            productsToPush,
            positionsToPush,
            forcedList,
            urlPathname,
            getStore().getState().UrlRewritesReducer,
            category
        );

        pushToDataLayer({
            event: GTM_EVENT_KEY_IMPRESSIONS,
            ecommerce: {
                ...productData,
            },
        });
    }
    // ^^^ not using debounce, as many impressions can come together
};

/** @namespace Scandiweb/Gtm/Event/List/fireProductClickEvent */
export const fireProductClickEvent = debounceCallback(async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    const productData = await getProductClickData(
        product,
        position,
        forcedList,
        urlPathname,
        urlRewriteData,
        category
    );

    pushToDataLayer({
        event: GTM_EVENT_KEY_PRODUCT_CLICK,
        ecommerce: {
            ...productData,
        },
    });
});
