import { ProductBundleOptionsComponent as ProductBundleOptions } from 'Component/ProductBundleOptions/ProductBundleOptions.component';
import {
    ProductCustomizableOptionsComponent,
} from 'Component/ProductCustomizableOptions/ProductCustomizableOptions.component';
import {
    ProductComponent as SourceProduct,
} from 'SourceComponent/Product/Product.component';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

/** @namespace Theme/Component/Product/Component */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ProductComponent extends SourceProduct {
    renderCustomizableOptions() {
        const {
            product: {
                options,
                chameleon,
            },
            updateSelectedValues,
        } = this.props;

        if (!chameleon?.is_options_visible) {
            return null;
        }

        return (
            <ProductCustomizableOptionsComponent
              options={ options }
              updateSelectedValues={ updateSelectedValues }
              chameleon={ chameleon }
            />
        );
    }

    renderBundleOptions() {
        const {
            product: {
                items,
                chameleon,
            } = {},
            updateSelectedValues,
        } = this.props;

        if (!chameleon?.is_options_visible) {
            return null;
        }

        return (
            <ProductBundleOptions
              options={ items }
              updateSelectedValues={ updateSelectedValues }
            />
        );
    }
}
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export default ProductComponent;
